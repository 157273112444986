<template>
  <div>
    <div v-if="events.length !== null && !detailForm">
      <div v-if="idparcelle.length">
        <div class="columns">
          <div class="column">
            <b-taglist attached>
              <b-tag type="is-info" size="is-medium">
                {{ idparcelle.length }}
              </b-tag>
              <b-tag type="is-dark" size="is-medium"> Parcelle(s) </b-tag>
            </b-taglist>
          </div>
          <div class="column">
            <div v-if="displayDetails">
              <b-button
                type="is-primary"
                icon-left="plus-square"
                @click="showDetail"
              >
                Ajouter des détails (prix, pdf...)
              </b-button>
            </div>
          </div>
        </div>

        <ul>
          <li v-for="item in idparcelle" :key="item">
            <b>- {{ item }}</b>
          </li>
        </ul>
        <br />
      </div>
      <!-- EVENT TYPE -->
      <div v-if="bypass">
        <b-field>
          <b-switch v-model="tobypass" :disabled="!bypass">
            Prendre un raccourci
          </b-switch>
        </b-field>
        <br />
      </div>

      <b-field label="Événement d'animation foncière">
        <b-select
          placeholder="Sélectionner l'événement"
          v-model="postEvent.evenement"
          @change.native="checkDetail"
          required
        >
          <option
            v-for="option in displayEvents"
            :value="option.idevenement"
            :key="option.idevenement"
          >
            {{ option.idevenement }} - {{ option.libevenement }}
          </option>
        </b-select>
      </b-field>
      <!-- Dont create a new lot if existing lot -->
      <div v-if="createLot && !existinglot">
        <b-message type="is-warning" has-icon>
          Cet événement va créer un lot avec les parcelles sélectionnées. Les
          futurs événements seront affectés à cet ensemble de parcelles. Vous
          pouvez nommer votre lot pour le retrouver plus facilement.
          <br /><br />
          <b-field label="Nom du lot :">
            <b-input v-model="postEvent.lotname"></b-input>
          </b-field>
        </b-message>
      </div>
      <div v-if="existinglot > 0">
        <b-message type="is-warning" has-icon>
          Cet ensemble de parcelles fait partie d'un lot déjà existant. Les
          évènements ajoutés concerneront donc l'ensemble des parcelles listées
          ci-dessus.
        </b-message>
      </div>

      <!-- DATE -->
      <b-field label="Date de l'événement">
        <b-datetimepicker
          v-model="postEvent.date"
          :min-datetime="addMinute(new Date(lastdate), 1)"
          placeholder="Sélectionner la date"
        >
        </b-datetimepicker>
      </b-field>
      <!-- COMMENT -->
      <b-field label="Remarques sur l'événement">
        <b-input maxlength="200" type="textarea" v-model="postEvent.comment">
        </b-input>
      </b-field>

      <!-- Validation buttons -->
      <div class="buttons">
        <b-button
          icon-left="save"
          native-type="submit"
          @click="addEvent('enough')"
          class="is-info"
        >
          Valider l'enregistrement et quitter
        </b-button>
        <b-button
          icon-left="plus-circle"
          native-type="submit"
          @click="addEvent(postEvent.evenement, postEvent.date)"
          class="is-primary"
          v-if="add && !createLot"
        >
          Valider et ajouter encore
        </b-button>
      </div>
      <!-- end -->
    </div>
    <div v-if="events.length !== null && detailForm">
      <div>
        <!-- Go back button -->

        <b-button type="is-primary" icon-left="arrow-left" @click="showDetail">
          Revenir à l'évènement
        </b-button>
        <hr />
        <!-- PDF
                <b-field class="file">
                    <b-upload v-model="file" expanded>
                        <a class="button is-primary is-fullwidth">
                            <b-icon icon="upload"></b-icon>
                            <span>{{ file.name || "Téléverser un fichier"}}</span>
                        </a>
                    </b-upload>
                </b-field>
                -->
        <b-field>
          <b-upload
            v-model="dropFiles"
            multiple
            drag-drop
            expanded
            accept="application/pdf"
            @input="loadFile()"
          >
            <section class="section">
              <div class="content has-text-centered">
                <p>
                  <b-icon icon="upload" size="is-large"></b-icon>
                </p>
                <p>Glisser-déposer votre fichier</p>
              </div>
            </section>
          </b-upload>
        </b-field>

        <div class="tags">
          <span
            v-for="(file, index) in dropFiles"
            :key="index"
            class="tag is-primary"
          >
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteDropFile(index)"
            ></button>
          </span>
        </div>

        <!-- Price -->
        <div v-if="detailItems.prixachat">
          <hr />
          <span class="label">Prix d'achat</span>
          <b-field>
            <b-input
              type="number"
              step="0.01"
              placeholder="0.00"
              v-model="postEvent.prixachat"
            >
            </b-input>
            <p class="control">
              <span class="button is-static">€</span>
            </p>
          </b-field>
        </div>
        <!-- SAFER price -->
        <div v-if="detailItems.fraissafer">
          <hr />
          <span class="label">Frais SAFER</span>
          <b-field>
            <b-input
              type="number"
              step="0.01"
              placeholder="0.00"
              v-model="postEvent.fraissafer"
            >
            </b-input>
            <p class="control">
              <span class="button is-static">€</span>
            </p>
          </b-field>
        </div>
        <!-- Date start -->
        <div v-if="detailItems.datestart">
          <b-field label="Date de début">
            <b-datetimepicker
              v-model="postEvent.datestart"
              placeholder="Sélectionner..."
            >
            </b-datetimepicker>
          </b-field>
          <!-- Date end -->
          <b-field label="Date de fin">
            <b-datetimepicker
              v-model="postEvent.dateend"
              placeholder="Sélectionner..."
            >
            </b-datetimepicker>
          </b-field>
          <b-field label="Reconductibilité">
            <b-checkbox v-model="postEvent.reconductibilite">
              Reconductibilité ?
            </b-checkbox>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "EventForm",
  props: [
    "events",
    "eventsbypass",
    "bypass",
    "idparcelle",
    "lastdate",
    "add",
    "oldPostEvent",
    "existinglot",
    "pebIdToMod",
  ],
  watch: {
    oldPostEvent: {
      immediate: true,
      handler(newVal, oldVal) {
        console.log("New", newVal);
        console.log("Old", oldVal);
        this.postEvent = JSON.parse(JSON.stringify(newVal));
        if (this.postEvent.evenement) {
          let d = new Date(this.postEvent.date);
          this.postEvent.date = d;
        } else {
          this.postEvent = {};
        }
      },
    },
  },
  computed: {
    displayEvents() {
      if (this.tobypass && this.bypass) {
        return this.eventsbypass;
      } else {
        return this.events;
      }
    },
  },
  methods: {
    loadFile() {
      // console.log( 'PDF size : ' , this.dropFiles[0].size/1024/1024 + "MB");
      // Check size
      if (this.dropFiles[0].size / 1024 / 1024 < 3) {
        let reader = new FileReader();
        reader.readAsDataURL(this.dropFiles[0]);
        reader.onload = () => {
          this.b64 = reader.result;
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      } else {
        this.$buefy.snackbar.open({
          message: `Taille du PDF > 3 Mo`,
          type: "is-danger",
          position: "is-bottom-left",
        });
        this.dropFiles = [];
      }
    },
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    // TODO
    checkDetail() {
      if (this.tobypass === true) {
        this.eventsbypass.forEach((item) => {
          if (item.idevenement === this.postEvent.evenement) {
            this.displayDetails = item.details;
            this.createLot = item.creationlot;
            if (this.displayDetails) {
              this.$axios
                .get("detailItem?event=" + this.postEvent.evenement)
                .then(
                  function (response) {
                    this.detailItems = response.data[0].details;
                  }.bind(this)
                )
                .catch((error) => console.log(error));
            }
          }
        });
      } else {
        this.events.forEach((item) => {
          if (item.idevenement === this.postEvent.evenement) {
            this.displayDetails = item.details;
            this.createLot = item.creationlot;
            if (this.displayDetails) {
              this.$axios
                .get("detailItem?event=" + this.postEvent.evenement)
                .then(
                  function (response) {
                    this.detailItems = response.data[0].details;
                  }.bind(this)
                )
                .catch((error) => console.log(error));
            }
          }
        });
      }
    },
    showDetail() {
      this.detailForm = !this.detailForm;
    },
    addMinute(dt, minutes) {
      return new Date(dt.getTime() + minutes * 60000);
    },
    async addEvent(event, date = null) {
      if (this.postEvent.date) {
        const loadingComponent = this.$buefy.loading.open({
          container: null,
        });
        let savedate = this.postEvent.date;
        let delta = this.postEvent.date.getTimezoneOffset();
        this.postEvent.date = new Date(
          this.postEvent.date.getTime() - delta * 60000
        );
        let url = this.add ? "addevent" : "modevent";

        try {
          // Try the POST data
          await this.$axios.post(
            url,
            {
              parcelles: this.idparcelle,
              event: this.postEvent,
              pdf: this.b64,
              existinglot: this.existinglot,
              pebIdToMod: this.pebIdToMod,
            },
            { timeout: 10000 }
          );

          bus.$emit("updateMap", true); // Send signal to update the map
          bus.$emit("resetEvent", event, date);

          if (event === "enough") {
            this.postEvent = {};
          } else {
            this.postEvent = {};
            this.postEvent.date = this.addMinute(savedate, 1);
          }

          loadingComponent.close();
        } catch (error) {
          console.log(error);
          loadingComponent.close();
        }
      }
    },
  },
  data: function () {
    return {
      tobypass: false,
      postEvent: {},
      postDetail: {},
      displayDetails: false,
      detailForm: false,
      detailItems: {},
      // Files
      dropFiles: [],
      b64: null,

      createLot: null,
    };
  },
};
</script>

<style scoped></style>
