<template>
  <div>
    <div class="modal-card" style="width: auto; padding: 30px">
      <!--
            ██╗  ██╗███████╗ █████╗ ██████╗ ███████╗██████╗
            ██║  ██║██╔════╝██╔══██╗██╔══██╗██╔════╝██╔══██╗
            ███████║█████╗  ███████║██║  ██║█████╗  ██████╔╝
            ██╔══██║██╔══╝  ██╔══██║██║  ██║██╔══╝  ██╔══██╗
            ██║  ██║███████╗██║  ██║██████╔╝███████╗██║  ██║
            ╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝╚═════╝ ╚══════╝╚═╝  ╚═╝
            -->
      <header class="modal-card-head">
        <p class="modal-card-title">
          Informations sur l'animation foncière de la parcelle
          <b>{{ eventsinfo.idparcelle }} </b>
        </p>
        <div class="buttons">
          <b-button
            type="is-info"
            icon-left="arrows-alt-h"
            v-if="eventsinfo.lot.length"
            @click="displayMore = !displayMore"
          >
            Information du lot
          </b-button>

          <b-button type="is-success" @click="$parent.close()">
            Fermer
          </b-button>
        </div>
      </header>
      <!--
            ███████╗██╗   ██╗███████╗███╗   ██╗████████╗███████╗    ██╗     ██╗███████╗████████╗
            ██╔════╝██║   ██║██╔════╝████╗  ██║╚══██╔══╝██╔════╝    ██║     ██║██╔════╝╚══██╔══╝
            █████╗  ██║   ██║█████╗  ██╔██╗ ██║   ██║   ███████╗    ██║     ██║███████╗   ██║
            ██╔══╝  ╚██╗ ██╔╝██╔══╝  ██║╚██╗██║   ██║   ╚════██║    ██║     ██║╚════██║   ██║
            ███████╗ ╚████╔╝ ███████╗██║ ╚████║   ██║   ███████║    ███████╗██║███████║   ██║
            ╚══════╝  ╚═══╝  ╚══════╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝    ╚══════╝╚═╝╚══════╝   ╚═╝
            -->
      <section class="modal-card-body" v-if="!displayMore">
        <div class="field">
          <b-field grouped group-multiline>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium">Créateur</b-tag>
                <b-tag type="is-success" size="is-medium"
                  >{{ eventsinfo.user.firstname }}
                  {{ eventsinfo.user.lastname }}
                </b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium">Date</b-tag>
                <b-tag type="is-success" size="is-medium">{{
                  eventsinfo.created_at
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="eventsinfo.contactcen">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium">Contact CEN</b-tag>
                <b-tag type="is-warning" size="is-medium">
                  <b-icon icon="envelope" size="is-small"> </b-icon>
                  <a :href="'mailto:' + eventsinfo.contactcen.mail">
                    {{ eventsinfo.contactcen.nom }}
                  </a>
                  -
                  <a
                    :href="
                      'tel:+33' + eventsinfo.contactcen.tel_mobile.substr(1)
                    "
                  >
                    {{ eventsinfo.contactcen.tel_mobile }}
                  </a>
                </b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="eventsinfo.contactsafer">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium">Contact SAFER</b-tag>
                <b-tag type="is-warning" size="is-medium">
                  <b-icon icon="envelope" size="is-small"> </b-icon>
                  <a :href="'mailto:' + eventsinfo.contactsafer.mail">
                    {{ eventsinfo.contactsafer.nom }}
                  </a>
                  -
                  <a
                    :href="
                      'tel:+33' + eventsinfo.contactsafer.tel_mobile.substr(1)
                    "
                  >
                    {{ eventsinfo.contactsafer.tel_mobile }}
                  </a>
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>

        <div class="field">
          <b-field grouped group-multiline>
            <div class="control" v-if="eventsinfo.aire">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Aire d'intervention globale</b-tag
                >
                <b-tag type="is-primary" size="is-medium">{{
                  eventsinfo.aire.libaire
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="eventsinfo.habitat">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium">Milieu dominant</b-tag>
                <b-tag type="is-primary" size="is-medium">{{
                  eventsinfo.habitat.libhabitat
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control" v-if="eventsinfo.espece.length">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium">Espèce(s)</b-tag>
                <b-tag type="is-primary" size="is-medium">
                  <span v-for="sp in eventsinfo.espece" :key="sp.idespece">
                    {{ sp.nomlatin }},
                  </span>
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>
        <div class="field">
          <b-field grouped group-multiline>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Commune (code INSEE)</b-tag
                >
                <b-tag type="is-info" size="is-medium">{{
                  eventsinfo.commune
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Prefixe de section cadastrale</b-tag
                >
                <b-tag type="is-info" size="is-medium">{{
                  eventsinfo.prefixe
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Section cadastrale</b-tag
                >
                <b-tag type="is-info" size="is-medium">{{
                  eventsinfo.section
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Numéro de parcelle</b-tag
                >
                <b-tag type="is-info" size="is-medium">{{
                  eventsinfo.numero
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Millésime du cadastre</b-tag
                >
                <b-tag type="is-info" size="is-medium">{{
                  eventsinfo.millesime
                }}</b-tag>
              </b-taglist>
            </div>
            <div class="control">
              <b-taglist attached>
                <b-tag type="is-dark" size="is-medium"
                  >Surface cadastrale</b-tag
                >
                <b-tag type="is-info" size="is-medium"
                  >{{ contenanceToSurface(eventsinfo.contenance) }}
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>
        <b-table
          :data="eventsinfo.evenements"
          default-sort-direction="desc"
          default-sort="dateevenement"
        >
          <template>
            <b-table-column
              field="idparcelle_evenement"
              label="ID"
              width="40"
              numeric
              v-slot="props"
            >
              {{ props.row.idparcelle_evenement }}
            </b-table-column>

            <b-table-column
              field="dateevenement"
              label="Date"
              sortable
              v-slot="props"
            >
              {{ props.row.dateevenement }}
            </b-table-column>

            <b-table-column
              field="libevenement"
              label="Événement"
              v-slot="props"
            >
              <b-tooltip :label="props.row.libcategorie">
                <span
                  class="circle"
                  v-bind:style="{ 'background-color': props.row.color }"
                ></span>
              </b-tooltip>

              {{ props.row.libevenement }}
            </b-table-column>

            <b-table-column
              field="rqevenement"
              label="Remarques"
              v-slot="props"
            >
              {{ props.row.rqevenement }}
            </b-table-column>

            <b-table-column label="Créateur" v-slot="props">
              {{ props.row.firstname }} {{ props.row.lastname }}
            </b-table-column>
          </template>
        </b-table>
      </section>
      <!--
            ██████╗ ███████╗████████╗ █████╗ ██╗██╗     ███████╗
            ██╔══██╗██╔════╝╚══██╔══╝██╔══██╗██║██║     ██╔════╝
            ██║  ██║█████╗     ██║   ███████║██║██║     ███████╗
            ██║  ██║██╔══╝     ██║   ██╔══██║██║██║     ╚════██║
            ██████╔╝███████╗   ██║   ██║  ██║██║███████╗███████║
            ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝╚══════╝
             -->
      <section class="modal-card-body" v-if="displayMore">
        <p>Cette parcelle fait partie d'un lot.</p>

        <div v-for="item in eventsinfo.lot" :key="item.idevenement">
          <div class="card">
            <header class="card-header has-background-grey-lighter">
              <p class="card-header-title">
                {{ item.libevenement }}
              </p>
            </header>
            <div class="card-content">
              <div class="content">
                <table
                  class="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
                >
                  <tbody>
                    <tr>
                      <th>Nom du lot</th>
                      <th>Date de saisie de l'événement</th>
                      <th v-if="item.pdfpath">PDF</th>
                      <th v-if="item.prixachat">Prix d'achat</th>
                      <th v-if="item.fraissafer">Frais SAFER</th>
                      <th v-if="item.datestart">Date de début</th>
                      <th v-if="item.dateend">Date de fin</th>
                      <th
                        v-if="
                          item.reconductibilite === false ||
                          item.reconductibilite === true
                        "
                      >
                        Reconductible
                      </th>
                    </tr>

                    <tr>
                      <td>
                        {{ item.nomlot }}
                      </td>
                      <td>
                        {{ item.created_at }}
                      </td>
                      <td v-if="item.pdfpath">
                        <b-button
                          v-if="item.pdfpath"
                          type="is-info"
                          icon-left="download"
                          @click="show(item.pdfpath)"
                        >
                          Document
                        </b-button>
                      </td>
                      <td v-if="item.prixachat">
                        {{ item.prixachat }}
                      </td>
                      <td v-if="item.fraissafer">
                        {{ item.fraissafer }}
                      </td>
                      <td v-if="item.datestart">
                        {{ item.datestart }}
                      </td>
                      <td v-if="item.dateend">
                        {{ item.dateend }}
                      </td>
                      <td
                        v-if="
                          item.reconductibilite === false ||
                          item.reconductibilite === true
                        "
                      >
                        {{ item.reconductibilite === true ? "Oui" : "Non" }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--
            ███████╗ ██████╗  ██████╗ ████████╗███████╗██████╗
            ██╔════╝██╔═══██╗██╔═══██╗╚══██╔══╝██╔════╝██╔══██╗
            █████╗  ██║   ██║██║   ██║   ██║   █████╗  ██████╔╝
            ██╔══╝  ██║   ██║██║   ██║   ██║   ██╔══╝  ██╔══██╗
            ██║     ╚██████╔╝╚██████╔╝   ██║   ███████╗██║  ██║
            ╚═╝      ╚═════╝  ╚═════╝    ╚═╝   ╚══════╝╚═╝  ╚═╝
            -->
      <footer class="modal-card-foot">
        <vue-excel-xlsx
          :data="data"
          :columns="columns"
          :filename="eventsinfo.idparcelle"
          :sheetname="eventsinfo.idparcelle"
        >
          Exporter
        </vue-excel-xlsx>
      </footer>
    </div>
  </div>
</template>

<script>
// import {bus} from "../main";

export default {
  name: "ModalTableIndividuel",
  props: ["eventsinfo"],
  computed: {
    date() {
      return new Date();
    },
  },
  mounted() {
    // console.log(this.eventsinfo.evenements)
  },
  methods: {
    show(e) {
      this.$axios
        .get(`pdf?path=${e}`)
        .then(
          function (response) {
            // TODO : /!\ There is crappy stuff here...
            let a = document.createElement("a");
            a.href = "data:application/pdf;base64," + response.data.b64;
            a.download = "documentName.pdf";
            a.click();
          }.bind(this)
        )
        .catch((error) => console.log(error));
    },
  },
  data() {
    return {
      displayMore: false,
      columns: [
        {
          label: "ID èvenement",
          field: "idparcelle_evenement",
        },
        {
          label: "ID parcelle",
          field: "idparcelle",
          // dataFormat: this.priceFormat
        },
        {
          label: "Date",
          field: "dateevenement",
        },
        {
          label: "Evènement",
          field: "libevenement",
        },
        {
          label: "Remarque",
          field: "rqevenement",
        },
        {
          label: "Prénom",
          field: "firstname",
        },
        {
          label: "Nom",
          field: "lastname",
        },
      ],
      data: this.eventsinfo.evenements,
    };
  },
};
</script>

<style scoped>
a {
  color: black;
}

.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: white;
  font-size: 10px;
  font-weight: bold;
}
</style>
