<template>
    <div class="container">
        <section>
            <b-sidebar
                    type="is-light"
                    fullheight
                    left
                    :open.sync="openSearch"
                    :can-cancel="[false, true, false]">
                <div class="SideContainer">

                    <div class="buttons">
                        <b-button @click="openSearch = !openSearch"
                                  type="is-success"
                                  size="is-medium"
                                  expanded
                                  outlined
                        icon-left="arrow-left">
                            Cacher
                        </b-button>
                    </div>


                    <GeomFilter
                        mode="animationfonciere">
                    </GeomFilter>

                </div>
            </b-sidebar>
        </section>

        <section>
            <b-sidebar
                    type="is-light"
                    fullheight
                    left
                    :open.sync="openPrefs"
                    :can-cancel="[false, true, false]">
                <div class="SideContainer">

                    <div class="buttons">
                        <b-button @click="openPrefs = !openPrefs"
                                  type="is-success"
                                  size="is-medium"
                                  expanded
                                  outlined
                                  icon-left="arrow-left">
                            Cacher
                        </b-button>
                    </div>

                    <MapLayers
                        mode="animationfonciere"
                    >
                    </MapLayers>

                </div>
            </b-sidebar>
        </section>

        <div class="columns">
            <div class="column is-4">

                <div class="buttons">
                    <b-field>
                        <b-button @click="openSearch = !openSearch" icon-left="search">Recherche</b-button>
                        
                        <b-button @click="openPrefs = !openPrefs" icon-left="cog">Paramètres</b-button>

                        <b-tooltip label="Ajouter des parcelles non-suivies ou modifier les informations générale d'une parcelle."
                                   position="is-bottom"
                                   type="is-dark"
                                   size="is-small"
                                   multilined>
                            <b-radio-button v-model="userSelect"
                                            native-value="parcelle"
                                            type="is-info">
                            <b-icon icon="draw-polygon"></b-icon>
                                <span>Parcelles</span>
                            </b-radio-button>
                        </b-tooltip>

                        <b-tooltip label="Ajouter ou modifier des événements d'animation foncière sur des parcelles suivies."
                                   position="is-bottom"
                                   type="is-dark"
                                   size="is-small"
                                   multilined>
                            <b-radio-button v-model="userSelect"
                                            native-value="animation"
                                            type="is-info">
                                <b-icon icon="calendar-check"></b-icon>
                                <span>Événements</span>
                            </b-radio-button>
                        </b-tooltip>
                    </b-field>
                </div>

                <ManageParcelle
                        v-if="userSelect === 'parcelle'">
                </ManageParcelle>

                <ManageEvent
                        v-if="userSelect === 'animation'">
                </ManageEvent>


            </div>
            <div class="column is-8">
                <MapFoncicen
                    mode="animationfonciere"
                ></MapFoncicen>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MapFoncicen from '../components/Map.vue'
    import ManageParcelle from '../components/ManageParcelle.vue'
    import ManageEvent from "../components/ManageEvent";
    import MapLayers from "../components/MapLayers";
    import GeomFilter from "../components/GeomFilters";

    export default {
        components: {
            GeomFilter,
            MapLayers,
            ManageEvent,
            MapFoncicen,
            ManageParcelle,
        },
        data() {
            return {
                userSelect: 'parcelle',
                openSearch: false,
                openPrefs: false,
            }
        }
    }
</script>

<style scoped>

.container {
    margin: 10px 10px 10px 10px;
    max-width: 100% !important;
    max-height: 100% !important;
}

.SideContainer {
    margin: 30px 10px 10px 10px;
}

</style>

<style>

.b-sidebar .sidebar-content {
    background-color: #faffd3 !important;
    box-shadow: 5px 0px 13px 3px rgba(10, 10, 10, 0.1);
    width: auto !important;
    z-index: 39;
}

</style>
